var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box",class:{ 'empty-results': _vm.isDisabled }},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('assets.filter.filter_data')))]),_c('div',{staticClass:"filter-items"},[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.user_name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.name),expression:"currentFilters.name"}],staticClass:"input",class:{
                    filtered: _vm.currentFilters.name && Object.values(_vm.currentFilters.name).length > 0,
                    changed: _vm.currentFilters.name && _vm.currentFilters.name != _vm.activeFilters.name
                },attrs:{"type":"text","hide-details":""},domProps:{"value":(_vm.currentFilters.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilters()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters, "name", $event.target.value)}}})]),(_vm.temporalTemplatesIsAvailable)?_c('div',[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.template_type')))]),_c('v-select',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.templateType && Object.values(_vm.currentFilters.templateType).length > 0,
                        changed: JSON.stringify(_vm.currentFilters.templateType) != JSON.stringify(_vm.activeFilters.templateType)
                    },attrs:{"hide-details":"","items":_vm.optionsTemplateType,"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},model:{value:(_vm.currentFilters.templateType),callback:function ($$v) {_vm.$set(_vm.currentFilters, "templateType", $$v)},expression:"currentFilters.templateType"}})],1)]):_vm._e(),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.timeframe')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.timeframe && Object.values(_vm.currentFilters.timeframe).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.timeframe) != JSON.stringify(_vm.activeFilters.timeframe)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsTimeframes),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},model:{value:(_vm.currentFilters.timeframe),callback:function ($$v) {_vm.$set(_vm.currentFilters, "timeframe", $$v)},expression:"currentFilters.timeframe"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.timeframe,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.search_by')))]),(Object.values(_vm.optionsSites))?_c('v-select',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.sites,
                    changed: JSON.stringify(_vm.currentFilters.sites) != JSON.stringify(_vm.activeFilters.sites)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsSites),"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by')},on:{"change":function($event){return _vm.refreshSearch()}},model:{value:(_vm.currentFilters.sites),callback:function ($$v) {_vm.$set(_vm.currentFilters, "sites", $$v)},expression:"currentFilters.sites"}}):_vm._e(),(_vm.currentFilters.sites == 'locations')?_c('div',{staticClass:"locations"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_sites')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                        filtered:
                            _vm.currentFilters.locations &&
                            typeof _vm.currentFilters.locations !== 'undefined' &&
                            Object.values(_vm.currentFilters.locations).length > 0,
                        changed: JSON.stringify(_vm.currentFilters.locations) != JSON.stringify(_vm.activeFilters.locations)
                    },attrs:{"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                        "+_vm._s(index == 0 ? '' : '')+"\n                    ")]}}],null,false,2303138646),model:{value:(_vm.currentFilters.locations),callback:function ($$v) {_vm.$set(_vm.currentFilters, "locations", $$v)},expression:"currentFilters.locations"}}):_vm._e(),_c('Tags',{attrs:{"tags":_vm.currentFilters.locations,"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}})],1):(_vm.currentFilters.sites == 'groups')?_c('div',{staticClass:"custom"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_territories')))]),(Object.values(_vm.optionsGroups))?_c('v-autocomplete',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.groups && typeof _vm.currentFilters.groups !== 'undefined' && Object.values(_vm.currentFilters.groups).length > 0,
                        changed: JSON.stringify(_vm.currentFilters.groups) != JSON.stringify(_vm.activeFilters.groups)
                    },attrs:{"hide-details":"","items":Object.values(_vm.optionsGroups),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                        "+_vm._s(index == 0 ? '' : '')+"\n                    ")]}}],null,false,2303138646),model:{value:(_vm.currentFilters.groups),callback:function ($$v) {_vm.$set(_vm.currentFilters, "groups", $$v)},expression:"currentFilters.groups"}}):_vm._e(),_c('Tags',{attrs:{"tags":_vm.currentFilters.groups,"backImage":'menu_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}})],1):_vm._e()],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.issues.table_headers.state')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.state && Object.values(_vm.currentFilters.state).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.state) != JSON.stringify(_vm.activeFilters.state)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsStates),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},model:{value:(_vm.currentFilters.state),callback:function ($$v) {_vm.$set(_vm.currentFilters, "state", $$v)},expression:"currentFilters.state"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.state,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.role')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.roles && typeof _vm.currentFilters.roles !== 'undefined' && Object.values(_vm.currentFilters.roles).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.roles) != JSON.stringify(_vm.activeFilters.roles)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsRoles.data),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}]),model:{value:(_vm.currentFilters.roles),callback:function ($$v) {_vm.$set(_vm.currentFilters, "roles", $$v)},expression:"currentFilters.roles"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.roles,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.category')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.categories && typeof _vm.currentFilters.categories !== 'undefined' && Object.values(_vm.currentFilters.categories).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.categories) != JSON.stringify(_vm.activeFilters.categories)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsCategories.data),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}]),model:{value:(_vm.currentFilters.categories),callback:function ($$v) {_vm.$set(_vm.currentFilters, "categories", $$v)},expression:"currentFilters.categories"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.categories,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('template.general.tags.title')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.tags && typeof _vm.currentFilters.tags !== 'undefined' && Object.values(_vm.currentFilters.tags).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.tags) != JSON.stringify(_vm.activeFilters.tags)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsTags.data),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}]),model:{value:(_vm.currentFilters.tags),callback:function ($$v) {_vm.$set(_vm.currentFilters, "tags", $$v)},expression:"currentFilters.tags"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.tags,"nameTag":'name',"value":"id","removable":true}})],1)]),_c('FilterActions',{attrs:{"showClearFiltersButton":_vm.showClearFiltersButton,"clearFiltersCallback":_vm.resetFilters,"filterCallback":_vm.applyFilters,"changesDetected":_vm.changesDetected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }