<template>
    <div id="content" class="checklist">
        <!-- TEMPLATE CONFIG -->
        <template v-if="detail">
            <ChecklistDetail :id="checklistID" @back="closeDetail" />
        </template>

        <!-- TASKS TEMPLATES TABLE with FILTERS -->
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <div class="right-menu-container">
                    <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="add" bLabel="button.add_template"></Button>
                    <Button bType="more" :bCallback="showMoreOptions" />
                </div>
                <div class="more-options" v-if="moreOptions">
                    <Button id="more-download-btn" bType="file" :bCallback="showExportTemplatesModal" :bLabel="exportBtnLabel" />
                </div>
            </div>

            <div class="checklist-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterChecklist
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        v-if="loaded && !detail"
                        @resetPageDatatable="top(true)"
                        @newFiltersApplied="clearSelected"
                    ></FilterChecklist>
                </div>
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by="name"
                        :sort-asc="true"
                        :single-select="singleSelect"
                        show-select
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template #item.name="item">
                            <div class="name" @click="goToTemplateConfig(item.item.id)">
                                <span :class="{ pending: item.item.status == 2 }">{{ item.item.name }} {{ item.item.surname }}</span>
                                <div class="updated-by" v-if="getEmployee(item.item.last_update_user)">
                                    {{ $t('supervise.checklists.updated_by') }} {{ getEmployee(item.item.last_update_user).name }} {{ formatLastUpdate(item.item.last_update_date) }}
                                </div>
                                <div class="created-by" v-else-if="getEmployee(item.item.created_user)">
                                    {{ $t('supervise.checklists.updated_by') }} {{ getEmployee(item.item.created_user).name }} {{ formatLastUpdate(item.item.created_date) }}
                                </div>
                            </div>
                        </template>
                        <template #item.timeframe_id="item">
                            <div class="date" @click="goToTemplateConfig(item.item.id)">
                                <div class="timeframe-name">
                                    {{ getTimeFrame(item.item.timeframe_id) }}
                                </div>

                                <div class="hours">
                                    <div class="timeframe" v-html="getTimeFrameHour(item.item.timeframe_id)"></div>
                                    <div class="availability" v-if="formatAvailability(item.item)">
                                        {{ $t('supervise.checklists.limit_hour') }} {{ formatAvailability(item.item) }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- TEMPLATE TYPE -->
                        <template #item.template_type="item" v-if="temporalTemplatesIsAvailable">
                            <div @click="goToTemplateConfig(item.item.id)">
                                <span class="template-type" v-if="item.item.config && item.item.config.hasOwnProperty('temporary')">
                                    <span class="type">{{ $t('user.filters.tplTypes.temporal') }}</span>
                                    <span v-if="item.item.config.temporary.end_date"
                                        >{{ formatDate(item.item.config.temporary.start_date) }} - {{ formatDate(item.item.config.temporary.end_date) }}</span
                                    >
                                    <span v-else>{{ formatDate(item.item.config.temporary.start_date) }}</span>
                                </span>
                                <span v-else>{{ $t('user.filters.tplTypes.scheduled') }}</span>
                            </div>
                        </template>
                        <template #item.locations="item">
                            <div @click="goToTemplateConfig(item.item.id)">
                                <Tags
                                    v-if="item.item.locations && Object.values(item.item.locations).length > 0"
                                    :backImage="'location_inactive_s30.svg'"
                                    :tags="Object.values(item.item.locations)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <Tags
                                    v-else-if="item.item.groups && Object.values(item.item.groups).length > 0"
                                    :backImage="'menu_inactive_s30.svg'"
                                    :tags="Object.values(item.item.groups)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <div class="all-locations" v-else>{{ $t('product.all_available') }}</div>
                            </div>
                        </template>
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToTemplateConfig(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name"></Tags>
                                </template>
                            </div>
                        </template>
                        <template #item.category_id="item">
                            <div>
                                <span v-if="getCategory(item.item.category_id) !== null">
                                    {{ getCategory(item.item.category_id).name }}
                                </span>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToTemplateConfig(item.item.id)">
                                <Table-statuses :text="statusesText(item)" :status="statusesClass(item)"> </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && Object.values(items).length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.keys(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="add"
                            :buttonText="$t('button.add_template')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.checklist_template')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import FilterChecklist from '@/components/domain/checklist/filter'
import ChecklistDetail from '@/components/domain/templates/detail.vue'
import EmptyTable from '@/components/ui/EmptyTable'
import { TYPE } from '@/constants'
import Button from '@/components/ui/Button.vue'

export default {
    components: {
        FilterChecklist,
        ChecklistDetail,
        EmptyTable,
        TableStatuses,
        Button
    },
    name: 'TemplateChecklist',
    data() {
        return {
            moreOptions: false,
            singleSelect: false,
            selected: [],
            noChanges: false,
            type: TYPE.TASK,
            checklistID: false,
            detail: false,
            values: false,
            loaded: false,
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('user.user_name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '45%',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.availability_hour'),
                    value: 'timeframe_id',
                    align: 'start',
                    width: '160',
                    sortable: false,
                    sort: this.sortColumnDate,
                    class: 'header-table'
                },
                {
                    text: this.$t('product.sites'),
                    value: 'locations',
                    align: 'start',
                    width: '200px',
                    class: 'header-table'
                },
                {
                    text: this.$t('user.category'),
                    value: 'category_id',
                    align: 'start',
                    width: '200px',
                    class: 'header-table'
                },
                {
                    text: this.$t('template.general.status.label'),
                    value: 'status',
                    // width: '15%',
                    width: '150px',
                    align: 'start',
                    class: 'header-table header-status'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            // ('items: ', this.$store.getters['template/getList']())
            return this.$store.getters['template/getList']()
        },
        itemsLoaded() {
            return this.$store.getters['template/getItemsLoaded']
        },
        hasAccess() {
            return this.checkPermission('/template/checklist/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        rowsAreSelected() {
            return this.selected.length > 0 ? true : false
        },
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        },
        exportBtnLabel() {
            if (this.selected.length === 0) {
                return 'button.export_templates'
            } else if (this.selected.length === 1) {
                return 'button.export_selected_template'
            } else {
                return 'button.export_selected_templates'
            }
        },
        temporalTemplatesIsAvailable() {
            const flag = parseInt(this.$store.getters['account/getTemporalTemplatesFlag'])

            if (flag === 1) {
                return true
            } else return false
        }
    },
    methods: {
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        statusesText(item) {
            /* item.item.status == '1' ? $t('template.general.status.published') : item.item.status == '-2' ? $t('template.general.status.draft') : ''" :status="item.item.status == '1' ? 'active' : 'inactive' */
            if (item.item.status == '-2') {
                return this.$t('template.general.status.draft')
            }

            if (item.item.status == '-3') {
                return this.$t('template.general.status.ended')
            }

            return this.$t('template.general.status.published')
        },
        statusesClass(item) {
            /* item.item.status == '1' ? $t('template.general.status.published') : item.item.status == '-2' ? $t('template.general.status.draft') : ''" :status="item.item.status == '1' ? 'active' : 'inactive' */
            if (item.item.status == '-2') {
                return 'inactive'
            }

            if (item.item.status == '-3') {
                return 'ended'
            }

            return 'active'
        },
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return {
                            name:
                                self.organizationRender.allLangs[key].name +
                                ' <b>(' +
                                parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) +
                                '%)</b>',
                            backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db'
                        }
                    }
                })
            } else return false
        },
        goToTemplateConfig(item_id) {
            if (this.checkPermission('/template/checklist/idTemp')) {
                // this.$router.push({ name: 'ChecklistDetail', params: { id: item_id } })

                this.checklistID = item_id
                this.detail = true
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('template.form.no_permission'),
                    duration: 5000
                })
            }
        },
        add() {
            var self = this

            if (this.checkPermission('/template/checklist/add')) {
                if (this.temporalTemplatesIsAvailable) {
                    self.$overlay.hide()

                    // MODAL to choose between PLANNED or TEMPORAL TEMPLATE
                    this.$popup.temporalTpl({
                        textSave: this.$t('popup.select.text_save'),
                        type: 'tplTemporary',
                        callSave: function () {
                            if (this.$children[0].hasPeriod === true) {
                                self.$store.commit('template/setNewIsTemporal', false)

                                self.$store.commit('template/removeTemporaryTemplateConfig')
                                self.$popup.close()
                                self.openTplGallery()
                            } else {
                                self.$store.commit('template/setNewIsTemporal', true)

                                self.goToTemplateConfig(false)
                                self.$popup.close()
                            }
                        },
                        callCancel: function () {
                            self.$popup.close()
                        }
                    })
                } else {
                    self.openTplGallery()
                }
            } else {
                self.$snackbar.open({
                    message: self.$t('template.form.no_permission'),
                    duration: 5000
                })
            }
        },
        openTplGallery() {
            var self = this
            if (this.checkPermission('/template/checklist/add')) {
                this.$overlay.loading()
                this.$store.dispatch('tasks/loadGalleryTemplates').then(function () {
                    var data = self.$store.getters['tasks/getGalleryTemplates']
                    self.$overlay.hide()

                    self.$popup.galeryTemplates({
                        title: self.$t('public_templates.gallery.title'),
                        data: data,
                        textCancel: self.$t('button.cancel'),
                        callSave: function (tpl) {
                            if (tpl) {
                                self.$store.dispatch('tasks/importGalleryTemplate', { tpl_id: tpl }).then(function (response) {
                                    if (response && response.status) {
                                        self.goToTemplateConfig(response.data)
                                        self.$popup.close()
                                    } else {
                                        self.$popup.close()
                                    }
                                })
                            } else {
                                self.goToTemplateConfig(false)
                            }
                        }
                    })
                })
            }
        },
        closeDetail(save) {
            this.loaded = false
            this.$router.push({ name: 'TemplateChecklist', params: { save: save, noChangePageDatatable: true } })
            this.pagination.page = 1
            // this.detail = false;
            // this.checklistID = false;
        },
        load() {
            this.loaded = false
            var self = this
            var items = this.$store.getters['template/getList']()

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '160px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(3, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(3, 0, translationHeader)
                }
            }

            // TEMPLATE TYPE HEADER (only for accounts with temporary templates)
            if (self.temporalTemplatesIsAvailable) {
                const headersIncludeTemplateType = self.headers.filter((header) => header.value === 'template_type')

                if (headersIncludeTemplateType.length === 0) {
                    const templateTypeHeader = {
                        text: self.$t('user.filters.template_type'),
                        value: 'template_type',
                        align: 'start',
                        width: '200',
                        sortable: false,
                        sort: self.sortColumnDate,
                        class: 'header-table'
                    }
                    self.headers.splice(2, 0, templateTypeHeader)
                }
            }

            self.$store.dispatch('template/loadFilters', { type: self.type }).then(function () {
                if (self.$route.params.id) {
                    self.goToTemplateConfig(self.$route.params.id)
                } else if (self.$route.name == 'AddChecklistDetail') {
                    // self.add()
                    self.goToTemplateConfig(false)
                } else {
                    self.$overlay.hide()
                    self.loaded = true
                }
            })
        },
        formatAvailability(item) {
            if (item.start_hour && item.end_hour) {
                return (
                    moment()
                        .startOf('day')
                        .add(parseFloat(item.start_hour) * 60, 'minutes')
                        .format('HH:mm') +
                    ' ' +
                    this.$tc('assets.timing.to') +
                    ' ' +
                    moment()
                        .startOf('day')
                        .add(parseFloat(item.end_hour) * 60, 'minutes')
                        .format('HH:mm')
                )
            } else if (item.start_hour && !item.end_hour) {
                var maxTime = this.getTimeFrameHour(item.timeframe_id)
                maxTime = maxTime.substring(10, 16)
                return (
                    moment()
                        .startOf('day')
                        .add(parseFloat(item.start_hour) * 60, 'minutes')
                        .format('HH:mm') +
                    ' ' +
                    this.$tc('assets.timing.to') +
                    ' ' +
                    maxTime
                )
            } else if (!item.start_hour && item.end_hour) {
                var maxTime = this.getTimeFrameHour(item.timeframe_id)
                maxTime = maxTime.substring(3, 8)
                return (
                    maxTime +
                    ' ' +
                    this.$tc('assets.timing.to') +
                    ' ' +
                    moment()
                        .startOf('day')
                        .add(parseFloat(item.end_hour) * 60, 'minutes')
                        .format('HH:mm')
                )
            } else {
                return ''
            }
        },
        getTimeFrame(id) {
            var timeframe = this.$store.getters['template/getFilterTimeframe'](id)
            var result = timeframe ? timeframe.name : '-'
            if (id === 0) {
                result = ''
            }
            return result
        },
        getTimeFrameHour(id) {
            if (id === 0) {
                return "<span class='all-day'>" + this.$t('supervise.checklists.all_day') + '</span>'
            }
            var timeframe = this.$store.getters['template/getFilterTimeframe'](id)
            if (!timeframe) {
                return ''
            }
            return (
                this.$t('supervise.checklists.table_headers.from') +
                ' ' +
                timeFormat(timeframe.start_hour) +
                ' ' +
                this.$tc('assets.timing.to') +
                ' ' +
                timeFormat(timeframe.end_hour)
            )
        },
        sortColumnTimeFrame(a, b) {
            if (this.$store.getters['template/getFilterTimeframe'](a) == undefined) {
                return 1
            }
            if (this.$store.getters['template/getFilterTimeframe'](b) == undefined) {
                return -1
            }
            var startHourA = a
            var startHourB = b
            if (!a == 0) {
                var timeframeA = this.$store.getters['template/getFilterTimeframe'](a)
                startHourA = timeframeA.start_hour
            }
            if (!b == 0) {
                var timeframeB = this.$store.getters['template/getFilterTimeframe'](b)
                startHourB = timeframeB.start_hour
            }

            var timeframeB = this.$store.getters['template/getFilterTimeframe'](b)
            if (startHourA < startHourB) {
                return -1
            }
            return 1
        },
        sortColumnDate(a, b) {
            if (a < b) {
                return -1
            }
            return 1
        },
        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('templateChecklistsFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('templateChecklistsFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        },
        getTemplatesIdsList(listOfTemplatesObjs) {
            if (!listOfTemplatesObjs || listOfTemplatesObjs.length === 0) {
                return null
            }

            const ids = listOfTemplatesObjs.map((template) => {
                if (template.hasOwnProperty('id')) {
                    return template.id
                }
            })

            return ids
        },
        handleExportTemplates() {
            const self = this

            self.$overlay.loading()

            if (self.selected.length > 0) {
                const idsList = self.getTemplatesIdsList(self.selected)
                self.$store.commit('template/setTemplatesToExportIds', idsList)
            } else self.$store.commit('template/setTemplatesToExportIds', null)

            self.$store.dispatch('template/exportTemplates', { type: TYPE.TASK }).then((url) => {
                if (url) {
                    self.$overlay.hide()
                    window.open(url, '_blank')

                    self.moreOptions = false
                    self.$popup.close()
                } else {
                    self.$overlay.hide()
                    self.moreOptions = false
                    self.$popup.close()

                    // TODO: display an error msg
                }

                let optionsSelected = self.$store.getters['template/getDownloadTemplatesDisplayOptions']
                if(optionsSelected.length > 0) {
                    optionsSelected = optionsSelected.filter((option) => option.value).reduce((acc, option) => {
                        acc[option.name] = option.value
                        return acc
                    }, {})
                }
                const amplitudeProps = {
                    popupSelection: optionsSelected,
                    page: 'Checklist',
                }
                self.$amplitude.track('Export templates in PDF', amplitudeProps)
            })
        },
        showExportTemplatesModal() {
            const self = this

            const templatesQuantity = self.selected.length

            self.$popup.PDFDisplayOptions({
                callSave: this.handleExportTemplates,
                callCancel: () => {
                    self.moreOptions = false
                },
                config: {
                    quantity: templatesQuantity,
                    tool: 'template'
                }
            })
        },
        clearSelected() {
            this.selected = []
        },
        formatDate(date) {
            if (!date) {
                return null
            }
            const dateParts = date.split('-')

            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
        },
        formatLastUpdate(date) {
            let milliseconds = date * 1000;
            let timingTo = this.$tc('assets.timing.to');
            let formattedDate = moment(milliseconds).format(`D MMM YYYY [${timingTo}] HH:mm`);

            return formattedDate;
        },
        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            return employee
        },
        getCategory(id) {
            if (!id) {
                return false
            }
            var category = this.$store.getters['template/getFilterCategories'](id)
            if (category) {
                return category;
            } else {
                return null;
            }
        }
    },
    watch: {
        temporalTemplatesIsAvailable(val) {
            // TEMPLATE TYPE HEADER (only for accounts with temporary templates)
            const headersIncludeTemplateType = this.headers.filter((header) => header.value === 'template_type')

            if (this.temporalTemplatesIsAvailable && headersIncludeTemplateType.length === 0) {
                const templateTypeHeader = {
                    text: this.$t('user.filters.template_type'),
                    value: 'template_type',
                    align: 'start',
                    width: '200',
                    sortable: false,
                    sort: this.sortColumnDate,
                    class: 'header-table'
                }
                this.headers.splice(2, 0, templateTypeHeader)
            }
        }
    },
    created() {
        this.load()
    },
    mounted() {
        if (this.$route.params.id) {
            this.checklistID = this.$route.params.id
            this.detail = true
        } else {
            if (this.$route.name == 'ChecklistDetail') {
                this.detail = true
            } else {
                this.detail = false
            }
        }

        this.setFiltersSidebarIsHiddenValue()
    },
    beforeRouteLeave(to, from, next) {
        var self = this
        this.loaded = false

        if (self.$route.name === 'AddChecklistDetail') {
            self.$store.commit('template/setNewIsTemporal', false)
        }

        if (this.detail) {
            // COMMENTED FOR HASH MODE FIX
            // if (this.detail && window.location.pathname.split('/').length <= 3) {
            //     history.pushState(this.$route.params, null, this.$route.path)
            // }

            if (to.params.save == true) {
                self.$overlay.show()
                self.detail = false
                self.checklistID = false
                next()
                setTimeout(function () {
                    self.load()
                }, 100)
            } else {
                self.$popup.confirm({
                    message: self.$t('template.form.popup_quit_msg'),
                    textSave: self.$t('template.form.popup_quit_confirm'),
                    callSave: function () {
                        self.$overlay.show()
                        self.detail = false
                        self.checklistID = false
                        next()
                        setTimeout(function () {
                            self.load()
                        }, 100)
                    },
                    callCancel: function () {
                        self.$popup.close()
                        self.$overlay.hide()
                    }
                })
            }
        } else {
            next()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.checklist {
    .more-options {
        position: absolute;
        top: 60px;
        right: 24px;
        padding: 3px 3px 3px 3px;

        .btn-file {
            min-width: 183px;
        }
    }
    .checklist-container {
        height: calc(100% - 88px);
        display: flex;
        align-items: stretch;

        .data-table {
            .name {
                span {
                    font-family: $text-medium !important;
                }
                .updated-by, .created-by{
                    @include font-size(xs);
                    font-family: $text-light;
                }
            }

            .v-data-table,
            .v-data-table table {
                .timeframe-name {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $color-black;
                    width: 100%;
                }
                .hours {
                    @include display-flex();
                    flex-direction: column;
                    flex-wrap: wrap;

                    width: 100%;

                    .timeframe,
                    .availability,
                    .of {
                        @include font-size(xs);
                        font-family: $text-light;
                    }
                }
            }

            td:nth-child(3) {
                padding: 0;
            }

            .status {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: start;
                align-items: center;
            }

            .ball {
                display: inline-block;
                margin-right: 6px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }

            .ball-color {
                display: inline-block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin-right: 3px;
            }

            .template-type {
                display: flex;
                flex-direction: column;

                .type {
                    font-family: $text-light;
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
